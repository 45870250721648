<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!--선해임현황 기본정보-->
          <c-card title="LBL0010221" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- <c-btn
                  v-show="editable && popupParam.seniorStatusId && !isRevision"
                  label="LBLREV"
                  icon="restart_alt"
                  @btnClicked="setRevision" />
                <c-btn
                  v-show="editable && popupParam.seniorStatusId && isRevision"
                  label="LBLREVCANCEL"
                  icon="restart_alt"
                  @btnClicked="cancelRevision" /> -->
                <!--삭제-->
                <c-btn
                  v-if="editable && popupParam.seniorStatusId"
                  label="LBLREMOVE"
                  icon="remove"
                  @btnClicked="deleteData"/>
                <!--저장-->
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <!--선해임명-->
                <c-text
                  required
                  :editable="editable"
                  :disabled="true"
                  :afterIcon="[
                    { name: 'search', click: true, callbackName: 'searchSenior', color: 'teal' },
                    { name: 'close', click: true, callbackName: 'removeSenior', color: 'red' }
                  ]"
                  label="LBL0010222"
                  name="seniorName"
                  v-model="data.seniorName"
                  @searchSenior="openSenior"
                  @removeSenior="removeSenior">
                </c-text>
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-plant
                  required
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div> -->
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <!--부서 & 선임자-->
                <c-field 
                  required
                  type="dept_user" 
                  :editable="editable"
                  :data="data"
                  deptValue="seniorDeptCd"
                  label="LBL0010223" 
                  name="seniorUserId" 
                  v-model="data.seniorUserId" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <!--선임일-->
                <c-datepicker
                  required
                  label="LBL0010217"
                  :editable="editable"
                  name="seniorDate"
                  v-model="data.seniorDate"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                    <!--해임여부-->
                    <c-radio
                      :editable="editable"
                      :comboItems="dismissalFlagItems"
                      label="LBL0010224"
                      name="dismissalFlag"
                      v-model="data.dismissalFlag"
                      @datachange="datachange">
                    </c-radio>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                    <!--해임일-->
                    <c-datepicker
                      :editable="editable"
                      :disabled="data.dismissalFlag !== 'Y'"
                      label="LBL0010219"
                      :start="data.seniorDate"
                      name="dismissalDate"
                      v-model="data.dismissalDate"
                    />
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <!--관련자격증-->
                <c-text
                  :editable="editable"
                  label="LBL0010225"
                  name="relatedCertificate"
                  v-model="data.relatedCertificate">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <!--자격증번호-->
                <c-text
                  :editable="editable"
                  label="LBL0010226"
                  name="certificateNo"
                  v-model="data.certificateNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <!--자격취득일-->
                <c-datepicker
                  :editable="editable"
                  label="LBL0010227"
                  name="certificateAcquiDate"
                  v-model="data.certificateAcquiDate">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <!--관련 법규-->
                <c-multi-select
                  :editable="editable"
                  :isArray="false"
                  codeGroupCd="REGULATION_BILL_API_CD"
                  itemText="codeName"
                  itemValue="code"
                  label="LBL0010215"
                  :disabled="true"
                  name="relatedLaws"
                  maxValues="3"
                  v-model="data.relatedLaws">
                </c-multi-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <!-- 자격요건 -->
                <c-textarea
                  name="qualifiyRequire"
                  label="LBL0010228"
                  :disabled="true"
                  :rows="2"
                  v-model="data.qualifiyRequire"
                ></c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <!-- 관련근거 -->
                <c-textarea
                  name="relatedReason"
                  label="LBL0010229"
                  :rows="2"
                  v-model="data.relatedReason"
                ></c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <!-- 수행역할 -->
                <c-textarea
                  name="seniorReason"
                  label="LBL0010230"
                  :rows="2"
                  v-model="data.seniorReason"
                ></c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-6">
          <!--관련 교육과정 및 이수일-->
          <c-table
            ref="table"
            title="LBL0010231"
            tableId="equipmentInsp"
            :columns="grid.columns"
            :data="data.eduStatusList"
            gridHeight="340px"
            :filtering="false"
            :isExcelDown="false"
            :checkClickFlag="false"
            rowKey="seniorId"
          >
          </c-table>
        </div>
        <div class="col-6">
          <!--관련 선해임자료 및 자격증-->
          <c-card title="LBL0010232" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-sm-12 col-md-12 col-lg-12" style="height: 280px">
                <!--관련 선해임자료 및 자격증-->
                <c-upload
                  :attachInfo="attachInfo"
                  :editable="editable"
                  label="LBL0010232">
                </c-upload>
              </div>
            </template>
          </c-card>
        </div>
        <!-- <div class="col-6">
          <c-card title="LBLREV" class="cardClassDetailForm" topClass="topcolor-orange" v-if="popupParam.seniorStatusId">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                  <div class="col-2">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="LBLREVNO"
                      name="revisionNum"
                      v-model="data.revisionNum">
                    </c-text>
                  </div>
                  <div class="col-4">
                    <c-text
                      :editable="false"
                      label="LBLREVPERIOD"
                      name="regDtStr"
                      v-model="data.regDtStr">
                    </c-text>
                  </div>
                  <div class="col-3">
                    <c-text
                      :editable="false"
                      label="LBLREVUSER"
                      name="regUserName"
                      v-model="data.regUserName">
                    </c-text>
                  </div>
                  <div class="col-12">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="LBLREVREASON"
                      name="revisionContent"
                      v-model="data.revisionContent">
                    </c-text>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top: -18px !important;" id="revdiv">
                <c-table
                  ref="revision"
                  title="개정이력"
                  tableId="revision"
                  topBorderClass="topcolor-orange"
                  :columns="gridrev.columns"
                  :data="gridrev.data"
                  gridHeight="150px"
                  :columnSetting="false"
                  :expandAll="false"
                  :isFullScreen="false"
                  :usePaging="false"
                  :hideBottom="true"
                  :filtering="false"
                  @rowClick="rowClickRev"
                >
                </c-table>
              </div>
            </template>
          </c-card>
        </div> -->
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'senior-dismissal-status-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        seniorStatusId: '',
      }),
    },
  },
  data() {
    return {
      attachable: false,
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'DISMISSAL_ATTACH',
        taskKey: '',
      },
      grid: {
        columns: [
          {
            name: "educationCourseName",
            field: "educationCourseName",
            //교육과정
            label: "LBL0010233",
            style: 'width:250px',
            align: 'left',
            sortable: true,
          },
          {
            name: "educationCycleName",
            field: "educationCycleName",
            //교육주기
            label: "LBL0010234",
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: "evaluationDate",
            field: "evaluationDate",
            //최근 교육이수일
            label: "LBL0010235",
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: "eduNextDate",
            field: "eduNextDate",
            //차기 교육이수일
            label: "LBL0010236",
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      mappingType: 'POST',
      saveUrl: transactionConfig.mdm.senior.status.insert.url,
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      isRevision: false,
      data: {
        seniorStatusId: '',
        relatedLaws: '',
        seniorName: '',
        qualifiyRequire: '',
        seniorUserId: '',
        seniorDate: '',
        seniorDeptCd: '',
        seniorDeptName: '',
        relatedCertificate: '',
        dismissalDate: '',
        certificateNo: '',
        certificateAcquiDate: '',
        relatedReason: '',
        seniorReason: '',
        revisionNum: '',
        groupId: '',
        dismissalFlag: 'N',
        sysRevision: 1,
        revisionContent: '제정',
        seniorId: '',
        regDtStr: '',
        regUserName: '',

        eduStatusList: [],
      },
      dismissalFlagItems: [
        { code: 'Y', codeName: 'YES' },
        { code: 'N', codeName: 'NO' },
      ],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      gridrev: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            //개정번호
            label: 'LBLREVNO',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'revisionContent',
            field: 'revisionContent',
            //개정사유
            label: 'LBLREVREASON',
            align: 'left',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            //개정일시
            label: 'LBLREVPERIOD',
            style: 'width: 150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            //개정자
            label: 'LBLREVUSER',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
        ],
        data: [],
      },
      realKey: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.senior.status.get.url;
      this.insertUrl = transactionConfig.mdm.senior.status.insert.url;
      this.updateUrl = transactionConfig.mdm.senior.status.update.url;
      this.deleteUrl = transactionConfig.mdm.senior.status.delete.url;
      this.revlistUrl = selectConfig.mdm.senior.status.rev.url;
      this.getRevUrl = selectConfig.mdm.senior.status.rev.get.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.seniorStatusId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.seniorStatusId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          this.$set(this.attachInfo, 'taskKey', this.popupParam.seniorStatusId)
        },);
      }
    },
    saveData() {
      if (this.popupParam.seniorStatusId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      let saveMsg = 'MSGSAVE';  // 저장하시겠습니까?
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: saveMsg,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.seniorStatusId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.seniorStatusId = result.data
      this.$set(this.attachInfo, 'taskKey', this.popupParam.seniorStatusId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.getDetail();
    },
    openSenior() {
      this.popupOptions.title = 'LBL0010237'; // 선해임명 검색
      this.popupOptions.param = {
        type: 'single',
      };
      this.popupOptions.target = () => import(`${'./seniorDismissalPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeSeniorPopup;
    },
    closeSeniorPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.data.relatedLaws = data[0].relatedLaws;
        this.data.seniorName = data[0].seniorName;
        this.data.qualifiyRequire= data[0].qualifiyRequire;
        this.data.seniorClassCd = data[0].seniorClassCd;
        this.data.seniorFieldCd = data[0].seniorFieldCd;
        this.data.seniorId = data[0].seniorId;
      }
    },
    removeSenior() {
      this.data.relatedLaws = '';
      this.data.seniorName = '';
      this.data.qualifiyRequire = '';
      this.data.seniorClassCd = '';
      this.data.seniorFieldCd = '';
      this.data.seniorId = '';
    },
    datachange() {
      this.data.dismissalDate = ''
    }
  }
};
</script>
